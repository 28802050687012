function loadBibleGatewayTagger () {
  const bglinksScript = document.createElement("script");
  bglinksScript.setAttribute("src", "https://www.biblegateway.com/public/link-to-us/tooltips/bglinks-es.js");
  document.body.appendChild(bglinksScript);
  bglinksScript.onload = function() {
	BGLinks.version = "NVI-PT";
	BGLinks.linkVerses();
  };
}

window.delayedScriptLoaders.push(loadBibleGatewayTagger);
